import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";
import { useCallback, useMemo } from "react";

const ParticlesComponent = () => {
    const options = useMemo(() => {
        return {
            interactivity: {
                events: {
                    onClick: {
                        enable: true,
                        mode: "push",
                    },
                    onHover: {
                        enable: true,
                        mode: "repulse",
                    },
                    modes: {
                        push: {
                            quantity: 5,
                        },
                        repulse: {
                            distance: 45,
                        },
                    }
                }
            },
            particles: {
                links: {
                    enable: true,
                    distance: 45,
                },
                move: {
                    enable: true,
                    speed: { min: 0.3, max: 1 },
                },
                opacity: {
                    value: { min: 0.2, max: 0.4 },
                },
                size: {
                    value: { min: 0.2, max: 1 },
                },

            },
        };
    }, []);

    const particlesInit = useCallback((engine) => {
        loadSlim(engine);
    }, []);
    return <Particles init={particlesInit} options={options} />;
}

export default ParticlesComponent;