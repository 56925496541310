import React, { useState } from 'react';
import "./MyModal.css";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';

function MyModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <li><button type="button" onClick={handleShow} id="suport" className="btn">My projects
        <span><FontAwesomeIcon icon={faHandshake} /></span></button></li>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sorry</Modal.Title>
        </Modal.Header>
        <Modal.Body>Right now I can't show you projects that are finished and published until I get approval from all clients.
          <br />Thank you for understanding.</Modal.Body>
      </Modal>
    </>
  );
}

export default MyModal;