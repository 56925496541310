import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Particles from "./components/Particles/Particles";
import Header from "./components/Header/Header";
import Content from "./components/Content/Content";
import About from "./components/About/About";
import Footer from "./components/Footer/Footer";
import { AnimatePresence } from "framer-motion";





function App() {

  return (
    <BrowserRouter>
      <Header />
      <Particles />
      <AnimatePresence>
        <Routes>
          <Route path="/" element={<Content />}></Route>
          <Route path="/about" element={<About />}></Route>
        </Routes>
      </AnimatePresence>
      <Footer />

    </BrowserRouter>
  );
}

export default App;
