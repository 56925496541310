import React, { useState } from 'react';
import { motion } from 'framer-motion';
import "./Content.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faHashtag, faLaptopCode, faDisplay, faKeyboard, faComputerMouse } from '@fortawesome/free-solid-svg-icons';
import MyModal from '../Modal/MyModal';




function Content() {

  const [showChannels, setShowChannels] = useState(false);
  const [showSetup, setShowSetup] = useState(false);





  return (
    <div className="container">
      <div className="content-wraper">
        <ul>
          <li><button type="button" id="Channels" onClick={() => { setShowChannels(!showChannels) }} className="btn">Channels <span><FontAwesomeIcon icon={faHashtag} /></span></button></li>

          {showChannels && (<motion.ul id="list"
            initial={{ opacity: 0, y: '-50%' }}
            animate={{ opacity: 1, y: '0%' }}
            exit={{ opacity: 0, y: '-50%', transition: { duration: '0.4' } }}
            transition={{ type: 'spring', stiffness: '100', duration: '1' }}
          >
            <li className="list-item-chanels"><button type="button" id='btn-list-social' className="btn"><a href='https://www.instagram.com/divnic_91/?hl=en'><span><FontAwesomeIcon icon={faInstagram} /></span><h3>Instagram</h3></a></button></li>
            <li className="list-item-chanels"><button type="button" id='btn-list-social' className="btn"><a href='https://www.youtube.com/channel/UC249n4ut5aOLjOjNGzdMAUA'> <span><FontAwesomeIcon icon={faYoutube} /></span><h3>YouTube</h3></a></button></li>
            <li className="list-item-chanels"><button type="button" id='btn-list-social' className="btn"><a href='https://github.com/divnic91'><span><FontAwesomeIcon icon={faGithub} /></span><h3>GitHub</h3></a></button></li>
          </motion.ul>)}


          
            <MyModal/>
          <li><button type="button" id="setup" onClick={() => { setShowSetup(!showSetup) }} className="btn">My setup <span><FontAwesomeIcon icon={faLaptopCode} /></span></button></li>
          {showSetup && (<motion.ul id="list"
            initial={{ opacity: 0, y: '-50%' }}
            animate={{ opacity: 1, y: '0%' }}
            exit={{ opacity: 0, y: '-50%', transition: { duration: '0.4' } }}
            transition={{ type: 'spring', stiffness: '100', duration: '1' }}
          >
            <li className="list-item-setup"><span><FontAwesomeIcon icon={faDisplay} /></span><h3>X2 Dell S2721HGF</h3></li>
            <li className="list-item-setup"><span><FontAwesomeIcon icon={faLaptopCode} /></span><h3>Macbook Pro M2</h3></li>
            <li className="list-item-setup"><span><FontAwesomeIcon icon={faKeyboard} /></span><h3>Redragon Kumara K552</h3></li>
            <li className="list-item-setup"><span><FontAwesomeIcon icon={faComputerMouse} /></span><h3>Logitech MX Master 3</h3></li>
          </motion.ul>)}

        </ul>
      </div>

    </div>
  )
}

export default Content;
