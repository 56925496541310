import React from 'react';
import { NavLink } from "react-router-dom";
import "./Footer.scss";

export default function Footer() {
  return (
    <div className='footer'>
      <ul className="navbar-nav ms-auto">
        <NavLink end to="/" id='glow' className={({ isActive }) => "nav-link" + (isActive ? " activeLink" : "")}>HOME</NavLink>
        <NavLink to="/about" id='glow' className={({ isActive }) => "nav-link" + (isActive ? " activeLink" : "")}>ABOUT ME</NavLink>
        <a href="mailto:divnicmiodrag@yahoo.com" id="glow">CONTACT</a>
      </ul>
    </div>
  )
}
