import React from 'react';
import { Link } from "react-router-dom";
import "./Header.scss";
import Portfolio from "../Pictures/Portfolio.png";

function Header() {
  return (

    <nav className="navbar">
      <Link to="/" className="navbar-brand ">
        <h1>&lt; Divnic - Dev /&gt;</h1></Link>
      <img src={Portfolio} className="App-logo" alt="logo"></img>
    </nav>

  )
}

export default Header;