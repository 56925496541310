import React from 'react';
import './About.scss';
import APicture from "../Pictures/About2.jpg";
import SPicture from "../Pictures/About.jpg";
import HPicture from "../Pictures/Html.png";
import CPicture from "../Pictures/Css.png";
import SASPicture from "../Pictures/Scss.png";
import FGPicture from "../Pictures/Figma.png"
import BPPicture from "../Pictures/Bootstrap.png";
import JSPicture from "../Pictures/JavaScript.png";
import RJSPicture from "../Pictures/ReactJS.png";
import TPicture from "../Pictures/Tehnology.jpg";


export default function About() {
    return (

        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-xl-4">
                            <img src={APicture} className="About-logo" alt="logo"></img>
                            <h4>About me</h4>
                            <p>I am a Front-End Web Developer. I finished the fourth degree
                                of electrical engineering school to become a computer
                                electrical technician, and at the same time I am also
                                studying information technology. I have been working
                                and participating in various projects for years,
                                improving and expanding my knowledge in that area.
                                I am currently working as a freelancer where I create
                                various projects for my clients and other companies...</p>
                        </div>



                        <div className="col-xl-4">
                            <img src={SPicture} className="About-logo" alt="logo"></img>
                            <h4>What is Front End Developer ?</h4>
                            <p>Everything you see on a website, such as images, various videos,
                                buttons, links, texts, animations, and more, is created by
                                a front-end web developer. The job of the front-end developer
                                is to take the vision and design concept from the client
                                and implement it through code, so that it is visible on
                                every internet browser, and is displayed, and stylized
                                on various devices such as: computers, tablets, and
                                phones... By using different programming languages,
                                the appearance and required functionality of the client
                                or other company for which the project is being created are obtained.</p>
                        </div>

                        <div className="col-xl-4">
                            <div>
                                <img src={TPicture} className="About-logo" alt="logo"></img>
                                <h4>My Frontend Technologies</h4>
                                <div className="tehnology">
                                    <div className="icon"><img src={HPicture} className="Picture" alt="logo"></img><h5>HTML</h5></div>
                                    <div className="icon"><img src={CPicture} className="Picture" alt="logo"></img><h5>CSS</h5></div>
                                    <div className="icon"><img src={JSPicture} className="Picture" alt="logo"></img><h5>JS</h5></div>
                                    <div className="icon"><img src={SASPicture} className="Picture" alt="logo"></img><h5>SASS</h5></div>
                                    <div className="icon"><img src={BPPicture} className="Picture" alt="logo"></img><h5>BOOTSTRAP</h5></div>
                                    <div className="icon"><img src={FGPicture} className="Picture" alt="logo"></img><h5>FIGMA</h5></div>
                                    <div className="icon"><img src={RJSPicture} className="Picture" alt="logo"></img><h5>REACT</h5></div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>




    )
};
